import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everybody, I am <span className="purple">Shreya Shet</span>{''} <span></span>
            based in <span className="purple"> Mumbai, India.</span>
            <br /> I am a final year student pursuing my bachelor's in Computer Engineering 
            <br />
            Currently I am working as a Web Developer Intern at <a href="https://ssingularity.co.in/" className="purple">SSingularity Tech.</a>
            <br />
            I am passionate about creating web applications and solving complex problems in the world of web development.
            <br />
           
          </p>
     
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
