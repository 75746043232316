import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";

import Type from "./Type";

function Home() {
  return (
    <section className="body-wrapper">
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 10 }} className="heading">
                Hi There!</h1>
                <div className="heading-name">
                I'm
                <strong className="main-name"> SHREYA SHET</strong>
              </div>

              <div className="type-div" style={{ padding:10, textAlign: "left"  }}>
                <Type />
              </div>
            </Col>

            <Col md={5} className="main-image" >
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      {/* <Home2 /> */}
    </section>
  );
}

export default Home;
