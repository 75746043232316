import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import hustlebee from "../../Assets/Projects/hustlebee.png";
import bloodline from "../../Assets/Projects/bloodline.png";
import tetromino from "../../Assets/Projects/tetromino.png";



function Projects() {
  return (
    <Container fluid className="project-section body-wrapper">
      <Particle />
      <Container>
        <h1 className="project-heading">
         My Projects that make <strong className="purple"> me Blush</strong>
        </h1>
      
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bloodline}
              isBlog={false}
              title="BloodLine"
              description="A blood donation app that acts as a medium between the blood donors and the receiver."
            
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hustlebee}
              isBlog={false}
              title="HustleBee"
              description="A Tech job portal that provides hardworking enthusiasts with all the kinds of jobs they are
              looking for & recruiters for the perfect employees"
              demoLink="https://hustlebee.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tetromino}
              isBlog={false}
              title="Tetromino"
              description="Tetris game with a twist."
              demoLink="https://tetromino-app.netlify.app/"              
            />
          </Col>

     

      

  
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
